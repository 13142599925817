@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap');

.page-container {
  padding-top: 50px;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  padding: 15px;
  min-height: 700px;
}
.btn-primary {
  background-color: #E76E0C;
  border-color: #E76E0C;
}

.btn-outline-primary {
  border-color: #E76E0C;
  color: #E76E0C;
}

.border-primary {
  border-color: #E76E0C !important;
}

.bg-primary {
  background-color: #E76E0C !important;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
  background-color: #E76E0C !important;
}

body {
  font-family: 'Comfortaa', cursive;
}

main {
  min-height: 80vh;
}

.sticky-table-header{
  position:sticky;
  top: 0;
  background-color: #26374D !important;
  color: #ffffff;
}

.date-picker-100 {
  width: 100%;
}

.conperio-orange { 
  color: #E76E0C;
}

.conperio-orange-bg { 
  background-color: #E76E0C;
}

.menu-link-inactive {
  color: white;
}

.menu-link-active {
  border: 2px solid #E76E0C;
  color: white;
  border-radius: 10px;
}

.sub-nav-link:hover, .sub-nav-link:focus, .sub-nav-link:active, .sub-nav-link.active {
  color: #ffffff !important;
}

.nav-link:hover, .nav-link:focus, .nav-link:active, .nav-link.active {
  color: #ffffff !important;
}



 .sub-nav-link-primary {
  color: #ffffff !important;
  background-color: #26374D !important;
  border-radius: 10px;
  margin-bottom: 5px;
}

.sub-nav-link-primary span {
  padding-left: 10px;
  padding-right: 10px;
}

.sub-nav-link-secondary {
  color: #26374D !important;
}

.sub-nav-link-primary:hover, .sub-nav-link-primary:focus, .sub-nav-link-primary:active, .sub-nav-link-primary.active {
  background-color: #26374D !important;
}

.sub-nav-link-secondary:hover, .sub-nav-link-secondary:focus, .sub-nav-link-secondary:active, .sub-nav-link-secondary.active {
  color: #26374D !important;
  border: #26374D 1px solid;
  border-radius: 10px;
}